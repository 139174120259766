import { Button } from "@material-ui/core";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { TransactionSignature, PublicKey, Transaction } from "@solana/web3.js";
import * as splToken from "@solana/spl-token";
import React, { FC, useCallback } from "react";
import { useNotify } from "./notify";

const RequestAirdrop: FC = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const notify = useNotify();

  const onClick = useCallback(async () => {
    if (!publicKey) {
      notify("error", "Wallet not connected!");
      return;
    }

    let signature: TransactionSignature = "";

    try {
      var myMint = new PublicKey("7hMNi9EVTTRyuUH7FybEcPLa7uG6amLWW8xAmV8qi47");

      const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
        "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
      );

      const associatedDestinationTokenAddr =
        await splToken.Token.getAssociatedTokenAddress(
          SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
          splToken.TOKEN_PROGRAM_ID,
          myMint,
          publicKey
        );

      const receiverAccount = await connection.getAccountInfo(
        associatedDestinationTokenAddr
      );

      if (receiverAccount === null) {
        const transaction = new Transaction().add(
          splToken.Token.createAssociatedTokenAccountInstruction(
            SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
            splToken.TOKEN_PROGRAM_ID,
            myMint,
            associatedDestinationTokenAddr,
            publicKey,
            publicKey
          )
        );

        signature = await sendTransaction(transaction, connection);
        notify("info", "Transaction sent:", signature);

        await connection.confirmTransaction(signature, "processed");
        notify("success", "Transaction successful!", signature);
      } else {
        notify("info", "Already created!");
      }
    } catch (error: any) {
      notify("error", `Transaction failed! ${error?.message}`, signature);
      return;
    }
  }, [publicKey, notify, connection, sendTransaction]);

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={!publicKey}
    >
      Create DIRT Token Account
    </Button>
  );
};

export default RequestAirdrop;
